import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import MainLayout from '../components/layout/layout';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      company {
        name
        nameLegal
        logo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;

function LayoutContainer(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data',
          );
        }
        return (
          <MainLayout
            {...props}
            site={{
              name: data.site.company.name,
              legalName: data.site.company.nameLegal,
              logo: data.site.company.logo.asset.fluid.src,
            }}
          />
        );
      }}
    />
  );
}

export default LayoutContainer;
