import React from 'react';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Contact from '../components/pages/contact';

const seo = {
  title: 'Contact',
  description: 'Contact page',
  keywords: ['Contact'],
};

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
