import React from 'react';
import { Row, Col } from 'antd';

import { Page, Section } from '../layout/page';
import ContactForm from '../layout/contact-form';
import Headline from '../layout/headline';

const Contact = () => (
  <Page>
    <Section color='white'>
      <Row gutter={32} type='flex' justify='start'>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Headline
            lead='Connect'
            emphasis='With Us'
            subtitle={`We'd love to hear from you.`}
            bgrnd='white'
          />
          <br />
          <h4>Pilots</h4>
          <p>
            We're currently developing applications for food and drink
            establishments. Help shape our products, services, and pricing by
            becoming an early adopter. Limited seats are available.
          </p>
          <h4>Partners</h4>
          <p>
            We're currently developing relationships with food banks and other
            food-based businesses. Help shape our integrations, products, and
            services by partnering with us.
          </p>
          <h4>Investment</h4>
          <p>
            Social impact investment opportunities are currently available.
            Let's talk.
          </p>
          <h4>Team Opportunity</h4>
          <p>
            We are seeking a rock star data scientist to join our team. The
            ideal candidate has expert skills in statistical analysis, data
            visualization, AI, and machine learning and understands the
            importance of social impact.
          </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <ContactForm />
        </Col>
      </Row>
    </Section>
  </Page>
);

export default Contact;
