import React from 'react';
import cx from 'classnames';

import styles from './headline.module.less';

const Headline = props => {
  const { lead, emphasis, subtitle, background, type } = props;

  return (
    <div className={cx(styles.headline, styles[background], styles[type])}>
      {(lead || emphasis) && (
        <h2 className={styles.title}>
          {lead}
          {emphasis && <span className={styles.emphasis}>{emphasis}</span>}
        </h2>
      )}
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
    </div>
  );
};

export default Headline;
