import React from 'react';
import { Form, Input, Button, Radio } from 'antd';
import { navigate } from 'gatsby';

import styles from './contact-form.module.less';
import './contact-form.less';

const { TextArea } = Input;
const FORM_SUCCESS_ROUTE = '/success';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class ContactFormComponent extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            ...values,
          }),
        })
          .then(results => {
            console.log('results', results);
            navigate(FORM_SUCCESS_ROUTE, {
              replace: true,
              state: { name: values.name },
            });
          })
          .catch(error => console.log(error));
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
        name='contact'
        onSubmit={this.handleSubmit}
        className={styles.form}
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        layout='vertical'>
        <div className={styles.botField}>
          <label>
            Don’t fill this out if you're human: <input name='bot-field' />
          </label>
        </div>

        <Form.Item label={<span>Name</span>}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input your name.',
                whitespace: true,
              },
            ],
          })(<Input name='name' />)}
        </Form.Item>

        <Form.Item label={<span>Business Name</span>}>
          {getFieldDecorator('business', {
            rules: [
              {
                required: false,
                message: 'Please input the name of your business.',
                whitespace: true,
              },
            ],
          })(<Input name='business' />)}
        </Form.Item>

        <Form.Item label='Email'>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid email.',
              },
              {
                required: true,
                message: 'Please input your email.',
              },
            ],
          })(<Input name='email' />)}
        </Form.Item>

        <Form.Item label='Phone'>
          {getFieldDecorator('phone', {
            rules: [
              { required: false, message: 'Please input your phone number!' },
            ],
          })(<Input name='phone' />)}
        </Form.Item>

        <Form.Item label='What is your primary interest?'>
          {getFieldDecorator('interest', {
            rules: [{ required: true, message: 'Please select one.' }],
          })(
            <Radio.Group
              name='interest'
              buttonStyle='solid'
              buttonStyle='outline'>
              <Radio.Button value='Pilot' autoFocus={true}>
                Pilot
              </Radio.Button>
              <Radio.Button value='Partnership'>Partnership</Radio.Button>
              <Radio.Button value='Investment'>Investment</Radio.Button>
              <Radio.Button value='Team Opportunity'>
                Team Opportunity
              </Radio.Button>
              <Radio.Button value='Other'>Other</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>

        <Form.Item label='How can we help you?'>
          {getFieldDecorator('message', {
            rules: [
              {
                required: true,
                message: 'Please input a message so we can serve you best.',
              },
            ],
          })(<TextArea name='message' rows={4} />)}
        </Form.Item>

        <Form.Item>
          <Button
            type='default'
            size='large'
            htmlType='submit'
            className={styles.action}>
            Send
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const ContactForm = Form.create({ name: 'register' })(ContactFormComponent);

export default ContactForm;
